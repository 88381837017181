$--GW-BOX-SHADOW-FOCUS: 0 0 0px 2px #000 !important;
body {
  line-height: 1.2;
  font-family: Muli, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #333333;
  margin: 0;
}
.jut__Container__container {
  --container-margin-x: 0;
  --container-margin-y-top: 0;
  --container-margin-y-bottom: 0;
  --container-max-width: none;
}

.jut__InputField__input.invalid {
  color: #000;
  border-color: var(--GW-COLOR-BORDER);
}

.wsibLabelStyle { 
  margin-bottom: var(--GW-SPACING-XS) !important;
}

.labelStyling {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700px;
}

.infolink {
  width: 25px;
  height: 25px;
  margin-top: 10px;
  img {
    width: 20px !important;
    height: 20px !important;
    max-width: unset !important;
  }
}

.mtb10 {
  margin: 10px 0px 10px 0px;
}

.pageHeadingH1 {
  font-weight: 400 !important;
  font-size: 37px !important;
  line-height: 47px !important;
}

.secondaryOrgLabel {
  max-width: 460px;
  margin-top: 10px;
}

.wsibDivMaginTop {
  margin-top: 2rem !important;
}

.label-bold {
  font-weight: bold;
}

.lable-size {
font-size: 27px;
}
.jut__ModalNext__modal {
  width: 514px;
}

.diModalHeader {
  padding-left: 56px;
  padding-right: 56px;
  text-align: center !important;
}

.diModalSubHeader {
font-size: 16px !important;
}

.diModalContent {
  padding-left: 52px;
  padding-right: 52px;
  text-align: center !important;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.diButtonWidth {
min-width: 211px;
@media (max-width: 359px) {
  min-width: 200px !important;
}
}

.jut__ModalBody__modalBody div {
  text-align: center;
}

.jut__ModalHeader__title {
  text-align: center;
}

.jut__ModalBody__modalBody.jut__ModalBody__hasVerticalScrollbar {
  box-shadow: none;
}

.jut__ModalBody__modalBody {
  min-height: 100px !important;
}

.jut__Main__container::before {
display: none;
}

.jut__Main__container::after {
display: none;
}

@include gw-breakpoint-only(phone) {
  .jut__ModalHeader__modalHeaderTitleLayout {
      padding-top: 0px !important;
  }
  .diModalContent, .diModalHeader {
      padding-left: 32px;
      padding-right: 32px;
  }
  .jut__ModalFooter__modalFooterFlexLayout {
      padding-bottom: 0px;
  }
  .diModalHeader {
      font-size: 16px !important;
      padding-top: 0px !important;
  }
  .diModalContent {
      margin-top: 0px;
      margin-bottom: 0px;
  }
}

@include gw-breakpoint-only(tablet) {
  .diModalContent, .diModalHeader {
      padding-left: 32px;
      padding-right: 32px;
  }
  .jut__ModalFooter__modalFooterFlexLayout {
      padding-bottom: 0px;
  }
  .jut__ModalHeader__modalHeaderTitleLayout {
      padding-top: 0px !important;
  }
  .diModalHeader {
      font-size: 16px !important;
      padding-top: 0px !important;
  }
  .diModalContent {
      margin-top: 0px;
      margin-bottom: 0px;
  }
}

@media screen and (max-width: 511px) {
  .diModalContent, .diModalHeader {
      padding-left: 32px;
      padding-right: 32px;
  }
  .jut__ModalFooter__modalFooterFlexLayout {
      padding-bottom: 0px;
  }
  .jut__ModalHeader__modalHeaderTitleLayout {
      padding-top: 0px !important;
  }
  .diModalHeader {
      font-size: 16px !important;
      padding-top: 0px !important;
  }
  .diModalContent {
      margin-top: 0px;
      margin-bottom: 0px;
  }
  .ReactModal__Content {
      overflow-y: auto;
      overflow-x: hidden;
  }
  .jut__ModalBody__modalBody {
      margin-top: 8px !important;
  }
}

.tabBarBtnContainer {
  button {
    background-color: #EFF0F1 !important;
    padding: 30px, 30px;
    width: 190px !important;
    height: 64px !important;
  }
  button:hover {
    color: black !important;
  }
  [class*="jut__TabBar__heading active"] {
    background-color: #E5F1F8!important;
    border-bottom: 8px solid #0076BF !important;
    box-shadow: none !important;
    width: 210px !important;
  }

  [class*="jut__TabSet__content"]{
    border: 2px solid #0076BF !important;
    padding: 0px 25px 25px 25px;
    height: fit-content;
  }
  [class*="jut__TabBar__tabBar"]{
    overflow-x: hidden !important;
    display: flex;
    justify-content: space-between;
    gap: 16px !important;
  }
  [class*="jut__TabBar__tabBarContainer"]{
    height: 64px !important;
  }
}
.hiddenAriaOnlyContent {
  position: absolute;
  left: -9999px;
  height: 1px;
  margin: 0px;
  padding: 0px;
}

.smallSpace {
  margin-bottom: 5px !important;
}
.normalSpace {
  margin-bottom: var(--GW-SPACING) !important;
}
.largeSpace {
  margin-bottom: var(--GW-SPACING-XL) !important;
}
.hugeSpace {
  margin-bottom: var(--GW-SPACING-XXXL) !important;
}

.jut__ModalHeader__title {
  white-space: pre-line;
  text-align: left;
}

.onBoarding-modal-en {
  overflow-y: auto;
  overflow-x: hidden;
  @include gw-breakpoint-only(phone) {
    width: 100%;
  }
  .jut__ModalHeader__modalHeader {
    margin-left: 26px;
    @include gw-breakpoint-only(phone) {
      margin-left: 0px !important;
    }
  }

  .jut__ModalHeader__modalHeaderTitleLayout {
    width: 468px;
    height:68px;
  }
  .jut__ModalHeader__title {
    width: 468px;
    text-align: left;
    display: flex;
    justify-content: left;
    white-space: pre-line;
    h2 {
      @include gw-breakpoint-only(phone) {
        padding-right: 7px;
        max-width: 410px;
    }
      @media (min-width: 360px) and (max-width: 370px) {
      max-width: 326px;
      padding-right: 7px !important;
      width: 100%;
    }
    }
  }

  .jut__ModalBody__modalBody {
    margin-left: 56px;
    margin-right: 56px;
    max-width: 468px;
    @include gw-breakpoint-only(phone) {
      padding-top: 52px !important;
      margin-bottom: 8px;
      margin-left: 10px !important;
      margin-right: 10px !important;
      max-width: 468px;
    }
    @media (min-width: 360px) and (max-width: 370px) {
      padding-top: 94px !important;
    }
    p {
    text-align: left;
    max-width: 468px;
    margin-top:8px;
    @include gw-breakpoint-only(phone) {
      padding-left: 0px !important;
    }
    @media (min-width: 740px) and (max-width: 1024px) {
      padding-left: 0px !important;
    }
  }
}
  .jut__ModalFooter__modalFooter {
    .jut__Flex__gwDirectionRow {
      flex-direction: row !important;
    }
    .jut__Button__primary {
      margin-bottom: 0px;
    }
    .jut__Flex__gwJustifyContentEnd {
      justify-content: flex-start;
    }
    .jut__ModalFooter__modalFooterFlexLayout {
      height: auto;
      width: 468px;
    }
  }
}
@include gw-breakpoint-down(tablet) {
  .jut__ModalBody__modalBody {
    max-width: 468px;
    p{
      padding-left: 31px;
    }
  }
}
@include gw-breakpoint-down(phone) {
  .jut__ModalBody__modalBody {
    // margin-left: 10px !important;
    // margin-right: 10px !important;
    // max-width: 468px;
    p{
      padding-left: 4px;
    }
  }
}

  .onBoarding-modal-en-US {
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 56px !important;
    padding-right: 56px!important;
    padding-top: 40px!important;
    padding-bottom: 40px!important;
  [class*="jut__ModalBody__modalBody"] {
    max-width:468px;
  }
  .jut__ModalHeader__modalHeaderTitleLayout {
    width: 100%;
  }
  .jut__ModalHeader__title {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: left;
    white-space: pre-line;
  }

  .jut__ModalBody__modalBody {
    p {
    text-align: left;
    }
  }
  .jut__ModalFooter__modalFooter {
    .jut__Flex__gwDirectionRow {
      flex-direction: row !important;
    }
    .jut__Button__primary {
      margin-bottom: 0px;
    }
    .jut__Flex__gwJustifyContentEnd {
      justify-content: flex-start;
    }
    .jut__ModalFooter__modalFooterFlexLayout {
      height: auto;
    }
  }
}

.onBoarding-modal-fr {
    overflow-y: auto;
    overflow-x: hidden;
    .jut__ModalHeader__modalHeader {
      margin-left:49px;
    }
    .jut__ModalHeader__modalHeaderTitleLayout {
      width: 468px;
      height:68px;
    }
    .jut__ModalHeader__title {
      width: 468px;
      text-align: left;
      display: flex;
      justify-content: left;
      white-space: pre-line;
    }
    .jut__ModalBody__modalBody{
      margin-left: 56px;
      margin-right: 56px;
      max-width: 468px;
    p {
      text-align: left;
      max-width: 468px;
      margin-top:8px;
      
    }
  }
    [class*="jut__ModalBody__modalBody"] {
      max-width:468px;
    }
  .jut__ModalHeader__modalHeaderTitleLayout {
    width: 100%;
  }
  .jut__ModalHeader__title {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: left;
    white-space: pre-line;
    h2 {
      padding-left: 0px !important;
      line-height: 1.2;
      @include gw-breakpoint-only(phone) {
        padding-left: 12px !important;
    }
    @include gw-breakpoint-only(tablet) {
      padding-left: 0px !important;
    }
  }
  }

  .jut__ModalBody__modalBody {
    p {
    text-align: left;
    padding-top: 40px;
    
    @include gw-breakpoint-only(tablet){
    padding-left: 33px;
    }
    }
  }

  .jut__ModalFooter__modalFooter {
    .jut__Flex__gwDirectionRow {
      flex-direction: row !important;
    }
    .jut__Button__primary {
      margin-bottom: 0px;
    }
    .jut__Flex__gwJustifyContentEnd {
      justify-content: flex-start;
    }
    
    .jut__ModalFooter__modalFooterFlexLayout {
      height: auto;
    }
  }
}

.osbcMainContainer {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.5em;
  padding-right: 2.5em;
  outline: none;
}
.eduMainContainer{
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.5em;
  padding-right: 2.5em;
  outline: none;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: visible !important;
}
a {
  vertical-align: baseline !important;
}
a.bluelink,
a.bluelink:hover {
  text-align: left;
  font-size: 16px;
  font-family: Muli, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0076bf;
  text-decoration: underline !important;
  opacity: 1;
}

a.bluelink:focus-visible,
a.bluelink:focus {
  outline: none;
  box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS)
    var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000;
}

button.custom {
  --button-bg-color: transparent;
  --button-color: #0076bf;
  --GW-COLOR-BRAND-1: transparent;
  --GW-COLOR-BRAND-1-GRADIENT: transparent;
  --button-hover-color: #0076bf;
  --button-hover-bg-color: #fff;
  --button-hover-bg-image: transparent;
  --button-hover-border-color: #0076bf;
  --button-active-color: #0076bf;
  --button-active-bg-color: transparent;
  --button-active-bg-image: transparent;
  --button-active-border-color: transparent;
  --button-font-weight: 600;
  box-shadow: none;
  padding-left: 15px;
  padding-right: 15px;
}

.jut__Button__primary {
  border: 1px solid #0076bf !important;
}

.jut__Button__tertiary {
  border: none !important;
  :first-child {
    border-bottom: 1px solid transparent !important;
    text-decoration: underline;
  }
  border-radius: 0px !important;
}

.jut__Button__primary:hover {
  background-color: #fff !important;
  color: #0076bf !important;
  border: 1px solid #0076bf !important;
}

.jut__Button__primary:focus:hover,
.jut__Button__primary:focus {
  background-color: #0076bf !important;
  color: #fff !important;
  border: 1px solid #0076bf !important;
}

.jut__Button__tertiary:hover {
  background-color: transparent !important;
  color: #0076bf !important;
  :first-child {
    text-decoration: underline !important;
  }
}

.jut__Pagination__paginationSelect {
  border: none !important;
  text-decoration: none !important;
  border-radius: 8px !important;
  :first-child {
    text-decoration: none !important;
  }
}
.jut__FileUploadField__wrapper button {
  background-color: #0076bf !important;
  border: 1px solid #0076bf !important;
  color: #fff !important;
}
.jut__FileUploadField__wrapper button:hover {
  background-color: #fff !important;
  border: 1px solid #0076bf !important;
  color: #0076bf !important;
}

.jut__Pagination__paginationSelect:hover {
  border: none !important;
  text-decoration: none !important;
  border-radius: 8px !important;
  :first-child {
    text-decoration: none !important;
  }
}

button.custom:focus {
  --button-z-index: 10;
  box-shadow: 0 0 0px 2px #000 !important;
  --button-focus-color: #0076bf;
  --button-focus-bg-color: transparent;
  --button-focus-bg-image: transparent;
  --button-focus-border-color: transparent;
  outline: none;
}
button.custom:active,
button.custom:active:focus {
  box-shadow: none;
  outline: none;
}
div.jut__GenericSelectControl__option {
  padding: var(--generic-select-option-padding-y)
    var(--generic-select-option-padding-x);
  line-height: 1;
  font-size: 14px;
  color: var(--generic-select-option-color);
  cursor: pointer;
}

h2.pageHeading {
  --GW-FONT-SIZE-XL: 27px;
}

.warningMessage {
  color: #d90000;
  font-weight: 600;
  margin-bottom: 24px;
}

.jut__DataTable__noDataMessage {
  text-align: left;
  padding-left: 16px;
  font-size: 14px;
  color: #333 !important;
}

.jut__DataTable__tableHeaderCell {
  .jut__DataTable__headerMultiline {
    display: flex !important;
  }
}

.noScroll {
  position: relative;
  overflow: hidden;
}
a.resetToDefaultLinkStylebluelink,
a.resetToDefaultLinkStylebluelink:hover {
  text-align: left;
  font-size: 16px;
  font-family: Muli, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0076bf;
  opacity: 1;
  margin-top: 2rem;
}

a.resetToDefaultLinkStylebluelink:focus-visible,
a.resetToDefaultLinkStylebluelink:focus {
  box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS)
    var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000;
}


#iAmDropdown-wrapper,
#responsiveiAmDropdown-wrapper {
  .css-1hb7zxy-IndicatorsContainer {
    border: 1.5px solid;
    width: 26px;
    border-radius: 50%;
    height: 26px;
    align-self: center;
    .jut__GenericSelectControl__selectIcon {
      padding-left: 0px;
      padding-right: 0px;
      margin: auto;
    }
  }
}

.menuClose {
  .mir {
    font-size: 40px;
  }
}

//ModalNextProvider styles

.jut__ModalHeader__modalHeader {
  --modal-header-bg: none !important;
}

.jut__ModalNext__modal {
  text-align: center !important;
  --modal-min-height: auto !important;
  --modal-min-width: 45vh !important;
}

.jut__ModalFooter__modalFooter {
  --modal-footer-status-shadow-size: none;
  display:contents;
  .jut__Button__primary {
    border: none !important;
  }
  
  .jut__Button__tertiary {
    color: #fff !important;
    background-color: #0076bf !important;
    border: 1px solid #0076bf !important;
    :first-child {
      border-bottom: none !important;
    }
    border-radius: 50px !important;
  }
  
  .jut__Button__tertiary:hover {
    background-color: #fff !important;
    color: #0076bf !important;
    :first-child {
      border-bottom: none !important;
    }
  }
  
  
  .jut__Button__tertiary:focus:hover,
  .jut__Button__tertiary:focus {
    background-color: #0076bf !important;
    color: #fff !important;
    :first-child {
      border-bottom: none !important;
    }
  }
  
  .jut__Button__primary{
    background-color: #fff !important;
    color: #0076bf !important;
    border: none !important;
    background-image: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
  }

  .jut__Button__primary:hover {
    border-radius: 0px !important;
    background-color: #fff !important;
    color: #0076bf !important;
    :first-child {
      text-decoration: underline !important;
    }
  }
}

.jut__ModalHeader__modalHeaderTitleLayout {
  margin: auto !important;
}

.jut__Flex__gwDirectionRow {
  flex-direction: column;
}

.jut__Button__primary {
  margin-bottom: 10px;
}

.claimOverviewMainContainer {
  @extend .osbcMainContainer;
}

.rightMainContainer {
  @extend .osbcMainContainer;
}
.rightMainContainer {
  width: 100%;
}
.customRadio {
  .jut__FieldLabel__primaryLabel {
    font-weight: 600;
    white-space: nowrap;
  }
  .jut__RadioButton__radioButton {
    display: inline-block;
  }
  .jut__FieldComponent__reversed > :first-child,
  .jut__FieldComponent__reversed > :last-child {
    flex: none !important;
  }
}

.customClaimTypeRadio {
  .jut__FieldLabel__primaryLabel {
    white-space: nowrap;
    font-size: 14px;
  }
  .jut__RadioButton__radioButton {
    display: inline-block;
  }
  .jut__FieldComponent__reversed > :first-child,
  .jut__FieldComponent__reversed > :last-child {
    flex: none !important;
  }
}

.jut__Accordion__cardBody {
  margin: 0px;
  padding: 10px 0px;
}

.exactMatchContainer {
  .jut__CheckboxField__checkbox {
    justify-content: end !important;
  }
}

#DivPara {
  a {
    font-size: 16px;
    vertical-align: text-top;
  }
}
.w50 {
  width: 50%;
}

#linkUploadDocumentAgain,
#linkUploadDocumentAgain:hover,
#linkUploadDocumentAgain:focus,
#linkUploadDocumentAgain:focus-visible {
  color: #fff;
  text-decoration: underline;
}
// styles for Active Tab of sidenav
.active {
  background-color: #e5f1f8 !important;
}

#latestPaymentIssuesDatatable,
#currentBenefitDatatable,
#returnToWorkDatatable {
  .jut__Pagination__pageSizeSelectWrapper {
    display: none;
  }
  .jut__Pagination__paginationNav {
    width: 100%;
    justify-content: flex-end;
  }
  .jut__DataTable__noDataMessage {
    font-size: 14px;
    font-weight: normal;
    color: #333;
  }
  .ReactTable .rt-thead div.rt-th.-cursor-pointer::after {
    display: none !important;
  }
}

#latestPaymentIssuesDatatable,
#returnToWorkDatatable {
  .jut__Pagination__pagination {
    display: none !important;
  }
}
#mobilityDevicesDatatable,
#therapyDatatable {
  .jut__Pagination__pagination {
    font-size: 16px !important;
  }
  .jut__Pagination__paginationNavButton {
    font-size: 16px !important;
  }
  .jut__Pagination__paginationSelect {
    font-size: 16px !important;
  }
}
.w100 {
  width: 100%;
}

.jut__Accordion__accordionToggle {
  min-height: 40px;
  padding: 20px 0px;
  padding-top: 0px;
}
#ClaimSummaryArchive,#ClaimSummaryArchiveAdvanceSearch{
  .jut__Pagination__resultOfMessage {
    padding-bottom: 0px !important;
}
  .jut__Pagination__paginationSelect {
    padding-top: 2px !important;
}
}
#ClaimAdvanceSearchSummary,#ClaimSummary,#ClaimSummaryArchive,#ClaimSummaryArchiveAdvanceSearch {
  .jut__Pagination__paginationNav {
  display: flex;
}
.jut__Pagination__pageSizeSelectWrapper {
    margin-left: -25px;
}
.jut__Pagination__resultOfMessage {
    margin-left: -9px;
    padding-bottom: 2px;
}
.jut__DropdownMenu__menu {
  box-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}
.jut__Button__button:focus {
  box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS)
    var(--GW-BOX-SHADOW-FOCUS-SPREAD) rgba(0,0,0,0) !important;
}
.jut__Button__rightIcon {
  display: none !important;
}
.jut__Pagination__paginationSelect {
   background-color: #fff !important;
   cursor: default;
}
.jut__Button__tertiary:hover {
    color: #333333 !important;
}
.jut__DropdownMenu__menu .jut__DropdownMenu__link, .jut__DropdownMenu__menu .jut__DropdownMenu__link:hover {
  display: none !important;
}
}

@include gw-breakpoint-down(tablet) {
  .jut__ModalNext__modal {
    --modal-min-height: 25vh !important;
    --modal-min-width: 38vh !important;
  }
  #selectAccountDropdownContainer .jut__FieldLabel__fieldLabelContainer {
    flex:1;
    flex-basis: 0%;
  }
  .claimOverviewMainContainer {
    padding: 0px;
  }
  .rightMainContainer {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  #account_mailDetail-main-container {
    .rightMainContainer {
      margin-left: 0;
    }
  }
}

/*for tablet css start*/
@include gw-breakpoint-only(tablet) {
  .jut__DataTable__dataTable {
    width: 1400px;
  }
  .jut__ModalNext__modal {
    --modal-min-width: 0vh !important
  }
  .jut__Pagination__pagination {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
  }
  .jut__Pagination__pageSizeSelectWrapper {
    justify-content: flex-start;
    padding-bottom: inherit;
  }
  #responsiveiAmDropdown-wrapper {
    .jut__GenericSelectControl__control,
    .css-1uccc91-singleValue {
      color: white !important;
    }
  }
  .divScrollParent {
    overflow-x: auto;
  }
  .rightMainContainer {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .w50 {
    width: 100%;
  }
  #account_mailDetail-main-container {
    .rightMainContainer {
      margin-left: 0;
    }
  }
}

/*for tablet css end*/
/*for phone css start*/
@include gw-breakpoint-only(phone) {
  .jut__DataTable__dataTable {
    width: 1400px;
  }
  .jut__ModalNext__modal {
    --modal-min-width: 0vh !important
  }
  .jut__Pagination__pagination {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
  }
  .jut__Pagination__pageSizeSelectWrapper {
    justify-content: flex-start;
    padding-bottom: inherit;
  }
  #responsiveiAmDropdown-wrapper {
    .jut__GenericSelectControl__control,
    .css-1uccc91-singleValue {
      color: white !important;
    }
  }
  .divScrollParent {
    overflow-x: auto;
  }
  .rightMainContainer {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .w50 {
    width: 100%;
  }
  #account_mailDetail-main-container {
    .rightMainContainer {
      margin-left: 0;
    }
  }
}

@include gw-breakpoint-only(phone) {
  #selectedPartsTable {
      width:96% !important
  }

  .spaceOnAbove {
    margin-top: 25px;
  }

  #SubmittedDocumentsDataTable {
    width: 580px !important
  }
}

#tabsContainer .jut__TabBar__heading {
  background-color: #EFF0F1;
  width: -webkit-fill-available
}

#tabsContainer .jut__TabBar__tabBar {
  gap:10px
} 

@include gw-breakpoint-only(tablet) {
  #selectedPartsTable {
      width:60% !important
  }
  #SubmittedDocumentsDataTable {
    width:100%;
  }
}

/*for phone css end*/

#SubmittedDocumentsDataTable span {
  word-wrap: break-word !important;
}

#claimTombstone {
  .jut__InputField__input {
    --input-border-width: none !important;
  }
}

a.linkColor {
  text-align: left;
  font-size: 16px;
  font-family: Muli, Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0076bf;
  opacity: 1;
  text-decoration: underline !important;
}
a.linkColor:focus-visible,
a.linkColor:focus {
  outline: none;
  box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS)
    var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000;
}
.jut__Button__button:focus {
  box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS)
    var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000 !important;
}
.noDataMessage {
  font-size: 14px;
  font-weight: normal !important;
  color: #333 !important;
}

[for='ddlSubject'],
[for='txtBody'] {
  .jut__FieldLabel__primaryLabel {
    font-weight: 600;
    white-space: nowrap;
  }
}

/*Modal popup*/
.jut__ModalHeader__modalWarningStatus {
  --modal-header-bg: #2e98d9;
}
.jut__ModalFooter__modalFooter {
  .jut__Flex__gwDirectionRow {
    flex-direction: row !important;
  }
  .jut__Button__primary {
    margin-bottom: 0px;
  }
}

#mailListDatatable
  > div
  > div.rt-table
  > div.rt-thead.-header.jut__DataTable__tableHeader
  > div
  > div:nth-child(4)::after {
  content: '' !important;
}
#mailListDatatable
  > div
  > div.rt-table
  > div.rt-tbody.jut__DataTable__tableBody
  > div
  > div
  > div
  > span {
  cursor: pointer;
}

#AccountMailList
  > div
  > div.rt-table
  > div.rt-tbody.jut__DataTable__tableBody
  > div
  > div
  > div
  > span {
  cursor: pointer;
}
#repeatcard {
  button.custom {
    padding: 0 !important;
  }
}
div#repeatcard > div:last-child hr {
  display: none;
}
#mailDetail-main-container {
  button.custom {
    padding: 0 !important;
    border: unset !important;
    box-shadow: none !important;
  }
  button.custom:hover {
    text-decoration: underline;
  }
  button.custom:focus {
    background-color: #fff !important;
    color: #0076bf !important;
    border-radius: 0px !important;
  }
  .rightMainContainer {
    padding-left: 0;
    padding-right: 0;
  }
  #MainDivSendMessage {
    margin-bottom: 20px;
  }
}
.jut__Loader__loader {
  display: none;
}

#mailList-main-container {
  button.custom {
    padding: 0 !important;
  }
  .claimOverviewMainContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

.customHyperLink {
  font-size: 16px !important;
}

.jut__Modal__modalStateInfoIcon {
  display: none !important;
}

.jut__Modal__modalFooter {
  text-align: center !important;
}
.jut__Modal__message {
  text-align: center !important;
}
.jut__Modal__modalHeaderContent {
  justify-content: center !important;
}
.customAccordian {
  .jut__Accordion__cardHeading {
    border-bottom: 1px solid #333 !important;
  }
}
.jut__Modal__modalStateInfo::before {
  background-color: unset !important;
}
.jut__Modal__modalInner {
  .jut__Chevron__chevron.mir {
    color: #0076bf !important;
  }
}

.jut__Accordion__header {
  font-weight: normal !important;
  left: 0 !important;
}

.jut__Chevron__chevronDefault {
  transform: rotate(90deg);
}

.jut__Chevron__open {
  transform: rotate(-90deg) !important;
}

.jut__Accordion__accordion .jut__Accordion__headerFocused {
  box-shadow: unset !important;
  // border: 1px dashed #888 !important;
  border: 2px solid #000 !important;
}

.reportsInfoSection  .jut__Accordion__cardTitle  {
    border-bottom: 8px solid #0076BF !important;
    border-top: 1px solid #0076BF !important;
    border-left: 1px solid #0076BF !important;
    border-right: 1px solid #0076BF !important;
    background-color:#E5F1F8 !important;;
  }

.jut__IconButton__iconButton:focus,
.jut__CheckboxField__checkboxElement:focus,
.jut__IconButton__iconButton:focus-visible {
  border: 2px solid #000 !important;
  box-shadow: unset !important;
}

.jut__RadioButton__radioButton
  input[type='radio']:checked
  + .jut__RadioButton__label::before {
  box-shadow: 0 0 0px 2px #000 !important;
}

input:focus,
textarea:focus,
.jut__FieldComponent:focus,
.jut__GenericSelectControl__controlFocused,
.jut__GenericSelectControl__control:focus,
.jut__Link__link:focus {
  box-shadow: 0 0 0px 2px #000 !important;
}

.jut__GenericSelectControl__controlFocused,
.jut__GenericSelectControl__control:focus,
.jut__Link__link:focus {
  box-shadow: 0 0 0px 2px #000 !important;
  input:focus {
    box-shadow: unset !important;
  }
}

.jut__ModalHeader__modalWarningStatus {
  --modal-header-bg: #fff;
}

.jut__ModalHeader__icon {
  display: none !important;
}

.customTertiary {
  font-weight: 900 !important;
  margin-left: 25px;
}

@include gw-breakpoint-down(tablet) {
  .customTertiary {
    margin-left: 0px !important;
  }
}

.ReactTable .-loading > div {
  display: none !important;
}

.semiBold {
  .jut__Link__link .jut__Link__text {
    color: #0076bf !important;
    text-decoration: underline;
  }

  font-weight: 600;
}

.jut__Link__link.return-to-work_semiBoldLink__ORvYu {
  text-decoration: underline;
}

.jut__Link__link.faq_semiBoldFAQ__3pz7G {
  text-decoration: underline;
}

.jut__Link__link.current-benefit_semiBold__QkYGe {
  text-decoration: underline;
}

.jut__Link__link.payment-history_semiBold__2pTNb {
  text-decoration: underline;
}

.jut__Link__link.therapy_semiBoldLink__YG1Ra {
  text-decoration: underline;
}

.jut__Link__link.mobility-devices_semiBoldLink__1BSxT {
  text-decoration: underline;
}

.jut__Link__link.injury-or-illness_semiBoldLink__3fOJ2 {
  text-decoration: underline;
}

.jut__Link__link.payment-issues_semiBoldLink__CH_eg {
  text-decoration: underline;
}

.jut__DataTable__dataTable
  .jut__DataTable__reactTable
  .jut__DataTable__tableHeader
  .jut__DataTable__tableHeaderCell {
  margin: 2px !important;
}

.jut__ModalFooter__modalFooter .jut__Flex__gwDirectionRow {
  flex-direction: column !important;

}

.jut__ModalBody__modalBody {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: max-content;
  // padding-left: 43px;
  h1 {
    font-weight: normal;
    font-size: 34px;
    max-width: 400px;
  }
  p {
    //max-width:400px;
    width: 100% !important;
  }
}

.jut__ModalBody__modalBody {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: max-content;
  h2 {
    font-weight: normal;
    font-size: 34px;
  }
  p {
     //max-width: 460px;
    //padding: 37px;
    // padding-left: 63px;
    width: 100% !important;
  }
}

.jut__ModalFooter__modalFooter .jut__Button__primary {
  margin: 0px !important;
}

#card1 {
  .jut__Accordion__header {
    font-weight: 600 !important;
    font-size: 27px;
  }
  .jut__CheckboxField__checkboxElement.jut__CheckboxField__checkboxElementSmall {
    width: 14px;
    height: 14px;
    line-height: 14px;
    top: 3px;
  }
}

.jut__DropdownSelectField__dropdownSelect.jut__DropdownSelectField__sizeableDropdown.jut__DropdownSelectField__inputSmall {
  padding: 0px !important;
}

.jut__Modal__dismissButton {
   display: none;
}

.jut__Modal__modalFooter {
  button {
    border-radius: 40px !important;
  }
}

.jut__ModalHeader__closeButton {
   display: none !important;
}

.jut__Modal__iconWrapper{
  display: none;
}
@media (max-width: 580px) {
  #ClaimAdvanceSearchSummary,#ClaimSummary,#ClaimSummaryArchive,#ClaimSummaryArchiveAdvanceSearch {
    .jut__Pagination__paginationNav {
    margin-left: -25px !important;
}
  }  
}
@media (min-width: 581px) and (max-width: 1024px) {
  #ClaimAdvanceSearchSummary,#ClaimSummary,#ClaimSummaryArchive,#ClaimSummaryArchiveAdvanceSearch {
    .jut__Pagination__paginationNav {
    margin-left: -25px !important;
}
  }  
}

#account_mailDetail-main-container {
  button.custom {
    padding: 0 !important;
    border: unset !important;
  }
  button.custom:hover {
    text-decoration: underline;
  }
  button.custom:focus {
    background-color: #fff !important;
    color: #0076bf !important;
    border-radius: 0px !important;
  }
  .rightMainContainer {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1.5em;
  }
  #MainDivSendMessage {
    margin-bottom: 20px;
  }
  .claimOverviewMainContainer {
    padding-left: 0;
    padding-right: 0;
  }
}


.jut__ClickableCard__clickableCard:hover {
  border: 3px solid black !important
}

.jut__FieldLabel__secondaryLabel {
  font-weight: 400 !important;
  color: #333333 !important;
}

.blueLinkdescTooltip {
  text-align: left;
  font-weight: 600;
  letter-spacing: 0px;
  opacity: 1;
}

.inlineTooltip {
  margin-left: 10px;
  figure {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}




.faqSection{
  padding: var(--GW-SPACING-XL) var(--GW-SPACING-XL) var(--GW-SPACING-XXL) var(--GW-SPACING-XL);
}

.gwSectionContainer {
  margin: var(--GW-SPACING-XL);
}

.gwSectionTitle {
  font-size: var(--GW-FONT-SIZE-LG);
  margin-bottom: var(--GW-SPACING-XS);
  margin-top: var(--GW-SPACING-XS);
}


.scheduleItemsTable {
    --gw-schedule-item-table-spacing: var(--GW-SPACING-XL);

    margin-top: var(--gw-schedule-item-table-spacing);

    & th {
        text-align: left;
    }
}

td.gwTableCell {
    --table-cell-border-width: 1px;
    --table-border-color: var(--GW-COLOR-BORDER);
    --table-cell-width: 200px;

    width: var(--table-cell-width);
    vertical-align: top;
    border: var(--gw-quote-table-border);
}
.facebookContent {
   --gw-facebook-share-btn-font-family: Helvetica, Arial, sans-serif;
   --gw-facebook-share-btn-border-radius: 3px;
   display: flex;
   justify-content: center;
   padding: calc(1.5 * var(--GW-SPACING-LG)) 0;
}

.facebookShare {
   --gw-facebook-share-width: 300px;
   --gw-facebook-share-border-radius: 3px;
   border: 1px solid var(--GW-COLOR-BACKGROUND-1-ACTIVE);
   border-radius: var(--gw-facebook-share-border-radius);
   max-width: var(--gw-facebook-share-width);
   text-align: center;
   padding: calc(1.5 * var(--GW-SPACING-LG));
}

.facebookShareButton {
   --button-primary-bg-color: var(--gw-facebook-share-background-color);
   --button-primary-hover-bg-color: var(--gw-facebook-share-background-color);
   --button-border-radius: var(--gw-facebook-share-btn-border-radius);
   --button-primary-color: var(--gw-white);
   --button-padding-x: calc(var(--GW-SPACING) - 1px);
   --button-padding-y: calc(0.5 * var(--GW-SPACING-XXS));
   --gw-button-min-width: unset;
   display: inline-flex;
   align-items: center;
   cursor: pointer;
   border: none;
}

.btnMessage {
   font-family: var(--gw-facebook-share-btn-font-family);
   font-weight: var(--GW-FONT-WEIGHT-BOLD);
   padding-left: calc(var(--GW-SPACING-XXS) - 1px);
}

.signUpContainer {

  > * {
      margin-bottom: var(--GW-SPACING-XL);
  }

  .signInLinkContainer {
    border-top: 1px solid var(--GW-COLOR-BORDER);
    padding-top: var(--GW-SPACING-XL);
  }

}


@import "~@jutro/theme/assets/sass/helpers";
//start
div:has(div > div[id^="DataTable_review"] ) { width:100%; padding: 8px 0px 16px 0px !important; }
div[id^='DataTable_review'] {width: 65% !important;}
div[id^='DataTable_review'] .rt-thead .rt-resizable-header-content {
    overflow: hidden !important;
    text-overflow: ellipsis;
}
div#reviewSupportingDocumentsSectionContainer + div + div > div {width:100%}
div#reviewSupportingDocumentsSectionContainer + div + div > div > div[id^='SubmittedDocumentsDataTable'] {width: 65% !important;}
//end
@include gw-breakpoint-only(phone) {
div[id^='DataTable_review'] 
{
    width: 700px !important;
}
div#reviewSupportingDocumentsSectionContainer + div + div > div {overflow-x: auto;}
div#reviewSupportingDocumentsSectionContainer + div + div > div > div[id^='SubmittedDocumentsDataTable'] 
{
    width: 700px !important;
}
#claimantsAnyOtherEarningsListNp{
    width: 100% !important;
}
}
i.fas.fa-edit.jut__Button__icon.jut__Button__leftIcon {
    width: 24px;
    height: 21.47px;
}
i.fas.fa-edit.jut__Button__icon.jut__Button__leftIcon + span {
    width: 24px;
    height: 21.47px;
}
div.notsorting::after {
    content: none !important;
}
div.tableHeaderFontClass{
    font-size: 16px ;
}
h1#reviewAndSubmitHeading {
    font-size: 37px;
}
.jut__Pagination__resultOfMessage {
    font-size: 12px;
  }
p#accountInfo p,
p#accountInfo
  {
    margin-bottom: 0px !important;
    width: 100% !important;
    @include gw-breakpoint-only(desktop) {
    margin-left: 4px;
    }
    margin-top: 3px !important;
    font-weight: 700;
    font-size: 16px;
  }
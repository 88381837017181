.modalCLass{
    width: 540px;
    padding: 30px;
    padding-bottom: 0px;
}

.modalHeader{
    font-size: 37px;
    font-weight: normal;
    line-height: 40px;
}

.p20{
    padding: 25px 0px;
    color: #000000;
}

.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
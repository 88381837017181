@import "~@jutro/theme/assets/sass/helpers";

.gwMediaSidebarWrapper {
    --gw-media-sidebar-width: 100%;
    --gw-media-sidebar-height: 75%;

    width: var(--gw-media-sidebar-width);
    height: var(--gw-media-sidebar-height);
    background-color: var(--GW-COLOR-BACKGROUND-1);
    margin-top: var(--GW-SPACING);
    @include gw-breakpoint-down(tablet) {
        display:none;
    }

    div {
        width: 100%;
    }
}

#player{
    width: 100%;
}

.rssStyle {
    padding: var(--GW-SPACING-XS);
    background: var(--GW-COLOR-DARK-CONTRAST);
    border-bottom: 1px solid var(--GW-COLOR-DARK-CONTRAST);

    a {
        text-decoration: none;
    }

    .muted {
        color: var(--GW-COLOR-TEXT-1);
        font-size: var(--GW-FONT-SIZE);
    }
}



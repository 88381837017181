.loaderMainContainer {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #0076bf 0% 0% no-repeat padding-box;
  opacity: 0.8;
  left: 0;
  top: 0;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .content {
    color: #FFFFFF;
  }
  .spinnerContainer {
    height: 98px;
    width: 98px;
    border: 8px solid transparent;
    border-top: 8px solid #FFFFFF;
    border-right: 8px solid #FFFFFF;
    border-radius: 100%;
    animation: spin 1.5s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

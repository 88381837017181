.link {
    text-decoration: var(--GW-NO-TEXT-DECORATION);
    color: var(--GW-COLOR-LINK-1);

    &:visited {
        color: var(--GW-COLOR-LINK-1);
    }

    &:hover {
        text-decoration: underline;
    }

    &:active {
        text-decoration: var(--GW-TEXT-DECORATION-UNDERLINE);
    }
}

.icon {
    margin-right: var(--GW-SPACING-XS);
}
@import '~@jutro/theme/assets/sass/helpers';

.leaveSessionButton{
    margin-right: 21px !important;
    width: 197px !important;

}

.extendSessionButton{
    width: 197px !important;
}
.session-modal-container {
    z-index: 251;

}
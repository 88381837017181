@import "~@jutro/theme/assets/sass/helpers";

.gw-inline-loader {
  --gw-inline-loader-margin: 0 .5rem 0;
  display: inline-block;
  margin: var(--gw-inline-loader-margin);
}

.gw-footer {
  @include gw-breakpoint-down(phone) {
    display: none;
  }
  --gw-footer-height: 8rem;
  --gw-footer-width: 100%;
  --gw-footer-padding: 0 40px;
  --gw-footer-bottom: 0;

  color: var(--GW-COLOR-TEXT-2);
  background-color: var(--GW-COLOR-BACKGROUND-1);
  height: var(--gw-footer-height);
  width: var(--gw-footer-width);
  padding: var(--gw-footer-padding);
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footerItem {
    display: flex;
    align-items: center;
  }

  .footerLink {
    --gw-footer-link-height: 20px;

    text-decoration: none;
    padding-left: var(--footer-nav-bar-item-padding-x);
    border-left: solid 1px var(--GW-COLOR-TEXT-2);
    height: var(--gw-footer-link-height);
    align-self: center;

    &:first-child {
      padding-left: 0;
      border-left: none;
    }

    a {
      text-decoration: none;
      color: var(--GW-COLOR-TEXT-2);

      &:hover {
        color: var(--GW-COLOR-TEXT-2);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.gw-phone-header-span {
  padding-left: var(--GW-SPACING-XXS);
}

.phone-header-grid {
  --gw-phone-header-grid-box-shadow: 0 0 10px 2px var(--GW-COLOR-LIGHT-CONTRAST);

  grid-gap: var(--GW-SPACING-XS);
  background-color: var(--GW-COLOR-FOCUS-2-HOVER);
  padding: var(--GW-SPACING-XS);
  overflow-y: scroll;
  overflow-x: hidden;
  width: var(--gw-phone-header-grid-width);
  position: absolute;
  box-shadow: var(--gw-phone-header-grid-box-shadow);
  right: inherit;
  height: 100%;
}

.gw-mobile-phonenumber{
  --gw-mobile-phonenumber-margin-top: -13px;
  --gw-mobile-phonenumber-margin-left: 13px;

  margin-top: var(--gw-mobile-phonenumber-margin-top);
  margin-left: var(--gw-mobile-phonenumber-margin-left);
  color: var(--GW-COLOR-DARK-CONTRAST);
  font-size: var(--GW-FONT-SIZE-LG);
}

.snapDrawerOpen {
  width: 100%;
  transition: transform 200ms linear, width 200ms linear;
  display: block;
}

.snapDrawer {
  display: none;
}

.snapDrawerOpenRight {
  right: 0;
  transform: translateX(0);
  @extend .snapDrawerOpen;
}

.snapDrawerOpenLeft {
  left: 0;
  transform: translateX(0);
  @extend .snapDrawerOpen;
}

.snapDrawerOpenTop {
  top: 0;
  transform: translateY(0);
  @extend .snapDrawerOpen;
}

.gw-phone-header {
  --gw-phone-header-grid-width: 0;
  font-size: var(--GW-FONT-SIZE-LG);
  top: 0;
  height: 100%;

  @extend .snapDrawer;

  &.gw-phone-header-open {
    --gw-phone-header-grid-width: 70%;

    @include gw-breakpoint-only(tablet) {
      --gw-phone-header-grid-width: 35%;
    }

    background-color: var(--GW-COLOR-TEXT-1-ACTIVE);
    position: fixed;
    z-index: 500;
    @extend .snapDrawerOpenRight;
  }
}

.gw-phone-header-menu-link, .gw-phone-header-menu-link:visited {
  --gw-phone-header-menu-link-padding: 10px 17px;
    color:var(--GW-COLOR-DARK-CONTRAST);
    text-decoration: none;
    display: block;
    padding: var(--gw-phone-header-menu-link-padding);
    cursor: pointer;
    font-size: var(--GW-FONT-SIZE-MD);
}

.gw-header {
  --gw-header-height: 6.5rem;
  --gw-header-padding: 0 3rem;
  --gw-header-image-height: 48px;
  --gw-header-image-width: 330px;
  --container-max-width: inherit;
  --gw-default-header-image: url('./images/logo.svg');

  --GW-SPACING: 0;

  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: var(--GW-COLOR-BRAND-2);
  padding: var(--gw-header-padding);
}

.footer-border div {
  border-right: 1px solid var(--GW-COLOR-TEXT-3-HOVER);
}

.footer-border div:last-child {
  border: none;
}

.gw-header-grid-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.gw-globalization-chooser{
  display: flex;
  height: auto;
}

.gw-header-img {
  @include gw-breakpoint-down(tablet) {
    --gw-header-image-width: var(--GW-SPACING-XXL);
    --gw-header-image-height: var(--GW-SPACING-XXL);
    --gw-header-image: var(--gw-mobile-header-image, var(--gw-default-header-image));
  }
  width: var(--gw-header-image-width);
  height: var(--gw-header-image-height);
  background-image: var(--gw-header-image, var(--gw-default-header-image));
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

img.gw-header-img-mobile {
  --gw-header-image-width: 30px;
  --gw-header-image-height: 100%;
  @extend .gw-header-img
}

.gwPhoneMenu, .gwPhoneMenu:active, .gwPhoneMenu:focus, .gwPhoneMenu:focus:active, .gwPhoneMenu:hover, .gwPhoneMenu:focus:active:hover {
  --button-font-size: var(--GW-FONT-SIZE-LG);
  border: none;
  background: none;
  box-shadow: none;
}

.gw-no-coverages {
  color: var(--gw-gray-dark-4);
  padding-top: var(--GW-SPACING-XL);
  padding-bottom: var(--GW-SPACING-XL);
  margin-left: var(--GW-SPACING-LG);
}

.alignRight {
  text-align: right;
}

.themeRoot .wizardTitleOnly {
  line-height: var(--GW-LINE-HEIGHT-LG);
  font-size: var(--GW-FONT-SIZE-LG);
  color: var(--GW-COLOR-BRAND-2);
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}

.themeRoot .wizardTitle {
  --gw-wizard-title-border-size: 2px;
  --gw-wizard-title-border-color: var(--GW-COLOR-BORDER);
  --gw-wizard-title-spacing: var(--GW-SPACING);

  border-bottom: var(--gw-wizard-title-border-size) solid var(--gw-wizard-title-border-color);
  padding: var(--gw-wizard-title-spacing) 0;
  margin-bottom: calc(var(--gw-wizard-title-spacing) * 2);
  @extend .wizardTitleOnly;
}

.themeRoot .wizardSubTitleContainer {
  margin-bottom: var(--GW-SPACING-LG);
}

.wizardTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include gw-breakpoint-down(phone) {
    flex-direction: column;
    align-items: normal;
  }
}

.themeRoot .pageTitle {
  font-size: var(--GW-FONT-SIZE-XXL);
  line-height: var(--GW-LINE-HEIGHT-XXXL);
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  color: var(--GW-COLOR-BRAND-2);
  margin-bottom: calc(2.25 * var(--GW-SPACING));
}

button.actionIcon {
  min-width: var(--GW-SPACING);
  min-height: var(--GW-SPACING);
  width: var(--GW-SPACING);
  height: var(--GW-SPACING);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: var(--GW-SPACING-LG);

  i {
    margin: 0;
    position: absolute;
  }
}

.gw-header-body-grid-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas: "header" "body";
}

$grid-positions: left, right, top, bottom, center, header, body;

@each $position in $grid-positions {
  .grid-slot-#{$position} {
    grid-area: unquote($position);
  }
}

$flow-directions: row, column, row-reverse, column-reverse;

@each $direction in $flow-directions {
  .flow-direction-#{$direction} {
    flex-direction: unquote($direction);
  }
}

.clauseContainer {
  --clause-border-size: 1px;
  --clause-border-color: var(--GW-COLOR-BORDER);

  border: var(--clause-border-size) solid var(--clause-border-color);

  &:not(:first-child) {
      margin-top: var(--GW-SPACING-XS);
  }
}
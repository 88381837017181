@import "~@jutro/theme/assets/sass/helpers";

.gwMediaSidebarTextContainer {
  background-color: var(--GW-COLOR-DARK-CONTRAST);
  margin-bottom: var(--GW-SPACING-XS);
  padding: var(--GW-SPACING-XXS);

  @include gw-breakpoint-down(tablet) {
    display:none;
  }
}

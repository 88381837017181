@import '~@jutro/theme/assets/sass/helpers';

.loginAgain{
    text-decoration-line: none !important; 
    font-weight: 800 !important; 
    margin-left: -17px;
}

.sessionExpiredHeading{
    font-weight: normal !important;
}

.sessionExpiredPage{
    margin-bottom: 21% !important;
}

@include gw-breakpoint-down(tablet) {
    .sessionExpiredPage {
        margin-bottom: 67% !important;
    }
}
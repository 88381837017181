
.gwAuthComponent {
    --gw-login-component-container-width: 480px;
    --gw-login-single-button-wrapper-margin: 6px 0 45px;

    flex-grow: 1;
    max-width: var(--gw-login-component-container-width);

    .heading {
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        font-size: var(--GW-FONT-SIZE-XXL);
        margin-bottom: var(--GW-SPACING-LG);
    }

    .forgotPasswordHeading {
        font-size: var(--GW-FONT-SIZE-XXL); 
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    }

    .forgotPasswordErrorMsg {
        color: var(--GW-COLOR-ALERT-ERROR);
        font-size: var(--GW-FONT-SIZE);
    }

    .actionsWrapperSingleButton {
        flex-direction: row;
        display: flex;
        align-items: center;
        margin: var(--gw-login-single-button-wrapper-margin);
    }

    .coupleButtonStyle {
        justify-content: space-between;
    }

    .singleButtonStyle {
        justify-content: flex-end;
    }

    .errorMsg {
        color: var(--GW-COLOR-ALERT-ERROR);
    }
}
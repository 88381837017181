:root {
    --gw-tiles-transition-time: .2s;
    --gw-tiles-active-height: 100%;

    --gw-tiles-header-color-height: 12px;
    --gw-tiles-header-color-hover-height: 12px;

    --gw-tiles-large-width: 200px;
    --gw-tiles-large-height: 22px;

    --gw-tiles-title-min-height: 2.5rem;
    --gw-tiles-height: 104px;

    --gw-tiles-title-top: 8px;
    --gw-tiles-medium-title-height: 3.2rem;

    --gw-active-tile-color-opactiy: 24%;
}

.gwTiles {
  padding: 0 calc(1.25 * var(--GW-SPACING-XXS));
  margin: var(--GW-SPACING-SM) 0;
  
  &:first-child {
      padding-left: 0px;     
  }

    a {
        text-decoration: none;
        color: var(--GW-COLOR-TEXT-1);
        transition: color var(--gw-tiles-transition-time);
    }

    .gwTile {
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        min-height: var(--gw-tiles-height);
        position: relative;
        border-radius: var(--GW-SPACING-XXS);
        background: var(--GW-COLOR-BACKGROUND-1);
        box-shadow: var(--GW-BOX-SHADOW-OUTER-2);

        &:hover {
            a {
                color: var(--GW-COLOR-TEXT-2);
            }
        }

        &.gwActive {
            .gwTileColor {
                height: var(--gw-tiles-active-height);
                opacity: var(--gw-active-tile-color-opactiy);
            }
        }
    }
}
.gwTilesLarge {
    .gwTile {
        width: var(--gw-tiles-large-width);
        height: var(--gw-tiles-large-width);
        padding: 0 var(--GW-SPACING-XS);
        &:not(.gwActive):hover {
            .gwTileColor{
                height: var(--gw-tiles-large-height);
            }
        }
    }
    .gwTileColor {
        height: var(--gw-tiles-header-color-height);
    }
    .gwTileTitle {
        min-height: var(--gw-tiles-title-min-height);
        font-size: var(--GW-FONT-SIZE-MD);
    }
    .gwTileContent {
        margin-top: calc(var(--GW-SPACING) * 2.5);
        font-size: var(--GW-FONT-SIZE-XXXL);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        margin-bottom: var(--GW-SPACING-SM);
    }
}

.gwTilesMedium {
    .gwTile {
        --gw-medium-tile-width-height: 132px;

        width: var(--gw-medium-tile-width-height);
        height: var(--gw-medium-tile-width-height);
        border-radius: var(--GW-BORDER-RADIUS);
        padding: 0 var(--GW-SPACING-XS);

        &:not(.gwActive):hover {
            .gwTileColor{
                height: var(--gw-tiles-header-color-hover-height);
            }
        }
    }
    .gwTileColor {
        height: var(--gw-tiles-title-top);
    }
    .gwTileTitle {
        min-height: var(--gw-tiles-medium-title-height);
        font-size: var(--GW-FONT-SIZE);
        line-height: var(--GW-LINE-HEIGHT);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    }
    .gwTileContent {
        font-size: var(--GW-FONT-SIZE-XL);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        margin-top: var(--GW-SPACING-XL);

        i {
            font-size: var(--GW-FONT-SIZE-XL);
        }
    }
}
.gwTileColor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: height var(--gw-tiles-transition-time);
    background-color: var(--GW-COLOR-BRAND-3);
}
.gwTileTitle {
    position: relative;
}
.gwTileContent {
    line-height: var(--GW-LINE-HEIGHT-MD);
    position: relative;
}

@import "~@jutro/theme/assets/sass/helpers";

:root {
	--gw-alert-border-radius: 2px;
	--gw-alert-min-height: 38px;
	--gw-alert-inner-margin: -16px 0;
	--gw-alert-content-flex: 1;
	--gw-alert-message-flex: 1;
	--gw-alert-content-width: 100%;
}

.gwAlert {
	margin-bottom: var(--GW-SPACING-XL);
	border: 1px solid transparent;
	border-radius: var(--gw-alert-border-radius);
	min-height: var(--gw-alert-min-height);
	font-size: var(--GW-FONT-SIZE-MD);

	.gwAlertIcon {
		font-size: var(--GW-FONT-SIZE-LG);
	}
}

.gwAlertInfo {
	background-color: var(--GW-COLOR-HIGHLIGHT-1);
	border-color: var(--GW-COLOR-HIGHLIGHT-1-HOVER);
	padding: var(--GW-SPACING);

	.gwAlertIcon {
		color: var(--GW-COLOR-FOCUS-2);
	}
}

.gwAlertWarning {
	background-color: var(--GW-COLOR-BACKGROUND-3);
	border-color: var(--GW-COLOR-BORDER);
	padding: var(--GW-SPACING);

	.gwAlertIcon {
		color: var(--GW-COLOR-ALERT-WARNING);
	}
}

.gwAlertContent {
	width: var(--gw-alert-content-width);
	padding-left: var(--GW-SPACING-XS);

	.gwAlertContentHeading {
		font-size: var(--GW-FONT-SIZE-MD);
		font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
		line-height: var(--GW-LINE-HEIGHT-MD);
		color: var(--GW-COLOR-TEXT-1);
		margin: 0;
	}

	.gwAlertContentSubHeading {
		font-size: var(--GW-FONT-SIZE);
		margin-bottom: 0;
	}

	.gwAlertContentInnerHeading {
		font-size: var(--GW-FONT-SIZE);
		margin-bottom: 0;
	}
}

.gwMessageInfo {
	margin-right: var(--GW-SPACING-XL);
	padding-top: var(--GW-SPACING);
}

.gwActionsInfo {
	text-align: right;

    @include gw-breakpoint-down(tablet) {
		text-align: left;
		margin-top: var(--GW-SPACING);
		margin-left: var(--GW-SPACING-XXXL);
    }
}

.gwInnnerInfo {
	padding-bottom: var(--GW-SPACING);
	margin: var(--gw-alert-inner-margin);
}

.continueTransaction {
	margin-left: var(--GW-SPACING);
}
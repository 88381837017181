.gwAlert{
	--gw-uwissues-offering-name-padding-top: 2rem;
	--gw-uwissues-offering-name-border: 1px solid var(--GW-COLOR-LIGHT-CONTRAST);
	--gw-alert-padding: 16px;
	--gw-waring-padding-right: 16px;
	--gw-uwissues-resolution-padding: 1em;
	--gw-uwissues-resolution-indent: 5em;

	background-color: var(--GW-COLOR-ALERT-WARNING);
	border: 1px solid var(--GW-COLOR-ALERT-WARNING-HOVER);
	padding: var(--gw-alert-padding);
	display: flex;

	.gwWarning {
		color: var(--GW-COLOR-ALERT-WARNING-ACTIVE);
		padding-right: var(--gw-waring-padding-right);
		font-size: var(--GW-FONT-SIZE-XL);
	}
	.gwAlertContent {
		width: 100%;
	}
	.alertOptions {
		padding: var(--gw-uwissues-resolution-padding);
		padding-left: var(--gw-uwissues-resolution-indent);
		margin-bottom: 0;
	}		
	.referToUWFormActions {
		display: flex;
		justify-content: space-between;
	}
	.UWFormActions {
		display: flex;
		justify-content: space-between;
	}
	.UWFormConfirmStyle {
		font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
	}
}
/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.themeRoot {
  --GW-BREAKPOINT-PHONE: 580px;
  --GW-BREAKPOINT-TABLET: 1024px; }

/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.themeRoot {
  --GW-BREAKPOINT-PHONE: 580px;
  --GW-BREAKPOINT-TABLET: 1024px; }

.tabcontiner {
  border-bottom: 1.2px solid #bbbab9;
  padding: 16px 20px;
  width: 241px;
  color: #333333;
  word-wrap: break-word;
  display: flex;
  background-color: #fff;
  --link-color: #333333;
  --GW-FONT-SIZE: 16px;
  --GW-COLOR-LINK-1: #333333;
  --GW-COLOR-LINK-1-HOVER: #333333;
  --GW-COLOR-LINK-1-ACTIVE: #333333;
  cursor: pointer; }

.catagory {
  padding: 16px 0px 10px 20px;
  font-weight: 600;
  font-size: 16px; }

.subcatagory:hover {
  background-color: #eff0f1; }

.icon {
  color: #0076bf;
  font-size: 16px;
  padding-right: 17px; }

.modalContainer {
  display: none; }

.linkContainer:focus-visible,
.linkContainer:focus {
  outline: none;
  box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS) var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000; }

@media (max-width: 1024px) {
  .leftNavigationMainContainer {
    display: none; } }

.display-flex-row, .headerMainLogoContainer, .headerLinksContainer, .osbcHeaderResponsiveContainer {
  display: flex;
  flex-direction: row;
  align-items: center; }

.headerMainContainer {
  background-color: #fff;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0rem 1rem #00050059; }

.osbcHeaderMainContainer {
  box-shadow: 0px 10px 10px 0px #bfbfbf99;
  margin-bottom: 50px; }

.osbcHeaderContainer {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.5em;
  padding-right: 2.5em; }

.iAmLabel {
  text-align: right;
  width: inherit;
  font-size: 14px;
  font-weight: 600; }

.headerContainer {
  padding: 0rem 20px;
  margin: 0rem auto;
  max-width: 1448px; }

.headerMainLogoContainer {
  justify-content: flex-start; }

.headerLogoContainer {
  width: 200px; }

.headerLogo {
  width: 180px !important;
  height: 62.8px !important;
  display: inline-block;
  background-repeat: no-repeat;
  margin-right: 32px;
  max-width: none !important; }

.headerLogo:focus-visible,
.headerLogo:focus {
  outline: none;
  box-shadow: 0 0 0px 2px #000 !important; }

.headerLabel {
  font-size: 37px;
  color: #333333; }

@media (max-width: 580px) {
  .headerLogo {
    width: 140px !important;
    height: 48px !important;
    display: inline-block;
    margin-right: 24px; } }

.headerLinksContainer {
  height: auto;
  padding-top: 0.5rem;
  justify-content: flex-end;
  align-items: baseline; }

.headerDropDown {
  letter-spacing: 0rem;
  color: #333333;
  padding: 0.2rem;
  width: 150px !important;
  --generic-select-control-font-size: 14px !important;
  --generic-select-control-border-width: 0px !important;
  --generic-select-control-bg-color: transparent !important;
  font-weight: 600; }

.headerHyperLinks,
.headerHyperLinks:hover,
.headerHyperLinks:active,
.headerHyperLinks:active:focus {
  color: #333333;
  padding-right: 0px;
  opacity: 1;
  box-sizing: none;
  border-radius: unset;
  background: none;
  box-shadow: none;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  --button-active-color: #333;
  --button-active-bg-color: transparent;
  --button-active-bg-image: transparent;
  --button-active-border-color: transparent;
  white-space: nowrap;
  padding-left: 12px; }

.headerHyperLinks:focus {
  color: #333333;
  padding-right: 0px;
  opacity: 1;
  box-sizing: none;
  border-radius: unset;
  background: none;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  --button-active-color: #333;
  --button-active-bg-color: transparent;
  --button-active-bg-image: transparent;
  --button-active-border-color: transparent;
  white-space: nowrap;
  padding-left: 24px; }

.headerHyperLinks:hover {
  text-decoration: underline;
  max-width: 402px !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  display: block !important;
  text-align: left !important; }

.headerHyperLinks {
  color: #333333;
  text-align: center;
  font-weight: bold;
  padding-bottom: 15px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
  overflow: hidden;
  text-decoration: none !important; }

.headerLoggedInUserNameContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;
  height: 50px; }

.headerLoggedInUserName {
  font-size: 24px;
  color: #333333;
  border-bottom: 8px solid #0D73B8;
  text-align: center;
  font-weight: bold;
  padding-bottom: 15px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
  overflow: hidden; }

.osbcHeaderResponsiveMainContainer {
  background-color: var(--GW-COLOR-BACKGROUND-1);
  background: #fff 0% 0% no-repeat padding-box; }

.osbcHeaderResponsiveContainer {
  justify-content: flex-start; }

.headerMenuContainer {
  text-align: right; }

.iconButtons {
  padding: 0rem;
  background: none; }

.responsiveHeaderContainer,
.modalContainer,
.modalBackDrop {
  display: none; }

@media (max-width: 1024px) {
  .menuButtonHeader {
    padding: 0px; }
  .osbcHeaderContainer {
    display: none; }
  .responsiveHeaderContainer {
    display: block;
    width: 100%;
    padding: 10px 0px; }
  .responsiveHeader {
    width: 100%;
    max-width: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 0.5rem 1rem; }
  .headerLogo {
    width: 140px !important;
    height: 48px !important;
    background-position: center;
    margin-right: 24px; }
  .fas .fa-times {
    text-align: right;
    margin: 0rem;
    font-size: 16px;
    color: #fff; }
  .headerMenuContainer {
    width: 100%; }
  .responsiveHyperLinks,
  .responsiveHyperLinks:hover,
  .responsiveHyperLinks:active,
  .responsiveHyperLinks:active:focus,
  .responsiveHyperLinks:focus {
    color: #333333;
    border-radius: 0px;
    border-bottom: none;
    background: none;
    box-shadow: none;
    font-weight: 600;
    border: none !important; }
  .headerHyperLinks:hover,
  .responsiveHyperLinks:hover {
    text-decoration: none; }
  .menuIconClicked {
    padding: 0rem;
    font-size: 16px; }
  .modalBackDrop {
    display: block;
    position: fixed;
    cursor: none;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 0.3;
    left: 0;
    top: 0; }
  .modalContainer {
    display: block;
    z-index: 300;
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    height: 80vh;
    background: #003359 0% 0% no-repeat padding-box;
    color: #fff;
    padding-top: 20px;
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    overflow: scroll;
    -webkit-overflow-scrolling: touch !important; }
  .modalHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px;
    padding-bottom: 0px;
    border-bottom: 2px solid #fff; }
  .modalHeaderTopContiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 25px 0px;
    justify-content: space-between;
    padding-top: 0px; }
  .headerLoggedInUserName {
    color: #fff;
    font-size: 16px;
    align-self: flex-end;
    border-bottom: none;
    padding: 20px 0px;
    padding-top: 0px;
    text-align: left;
    white-space: nowrap;
    width: 300px;
    overflow: hidden; }
  .modalContentContainer {
    padding-bottom: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column nowrap;
    text-align: center; }
  .dropdowmContainerCLass {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 2px solid;
    width: 85% !important;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px; }
  .headerDropDown {
    width: 100% !important;
    --GW-COLOR-TEXT-1: #ffffff !important;
    --generic-select-control-font-size: 16px !important;
    --generic-select-control-color: !important;
    --GW-Z-INDEX-HIGHER: 9999 !important;
    --GW-COLOR-BACKGROUND-1: transparent !important; }
  .iAmLabel {
    text-align: left;
    width: 23%;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap; }
  .menuIcon {
    font-size: 16px;
    color: #fff; }
  .borderBottomWhite {
    width: 100%; }
  [class*="jut__ModalNext__modal"] {
    overflow-y: auto !important;
    overflow-x: hidden !important; }
  .headerHyperLinks,
  .headerHyperLinks:hover,
  .headerHyperLinks:active,
  .headerHyperLinks:focus,
  .headerHyperLinks:active:focus {
    color: #fff !important;
    width: 100%;
    height: auto;
    font-weight: 600;
    border-radius: 0px;
    text-align: left;
    font-size: 16px;
    line-height: 40px;
    background-color: transparent !important; }
  .borderBottomWhite button {
    padding: 25px;
    padding-left: 0px; }
  .borderBottomWhite a,
  .borderBottomWhite button {
    border: unset !important;
    border-bottom: 2px solid #fff !important;
    border-radius: 0px;
    width: 85% !important; }
  .borderBottomWhite a,
  .borderBottomWhite div a,
  .borderBottomWhite a:hover,
  .borderBottomWhite a:active,
  .borderBottomWhite a:active:focus,
  .borderBottomWhite a:focus {
    padding: 25px;
    text-align: left;
    padding-left: 0px;
    justify-content: unset !important; }
  .borderBottomWhite a:active {
    border-left: 10px solid #fff !important;
    border-radius: 0px !important;
    width: 100% !important;
    padding-left: 30px; } }

@media (max-width: 580px) {
  .modalContainer {
    width: 75%; }
  .headerLogo {
    width: 120px !important;
    height: 41px !important;
    background-position: center;
    margin-right: 20.5px; }
  .headerLoggedInUserName {
    color: #fff;
    font-size: 16px;
    align-self: flex-end;
    border-bottom: none;
    padding: 20px 0px;
    padding-top: 0px;
    text-align: left;
    white-space: nowrap;
    width: 200px;
    overflow: hidden; } }

.responsiveSidenav {
  display: block !important;
  width: 100%; }

.tabcontiner {
  border-bottom: 1px solid #BBBAB9;
  padding: 16px 20px;
  font-weight: normal;
  word-wrap: break-word;
  display: flex;
  text-align: left;
  color: #333333;
  cursor: pointer;
  background-color: #fff;
  --link-color:#333333;
  --GW-FONT-SIZE: 16px;
  --GW-COLOR-LINK-1: #333333;
  --GW-COLOR-LINK-1-HOVER: #333333;
  --GW-COLOR-LINK-1-ACTIVE: #333333; }

.catagory {
  padding: 16px 0px 10px 20px;
  font-weight: 600;
  font-size: 16px; }

.subcatagory:hover {
  background-color: #E5F1F8; }

.icon {
  color: #0076BF;
  font-size: 16px;
  padding-right: 17px; }

.active {
  background-color: #fff !important; }

.active a {
  background-color: #fff !important; }

.tabcontiner {
  margin: 0px !important;
  width: 100% !important; }

.skipToMainContent {
  color: #0076BF;
  font-weight: 600;
  text-decoration: none !important;
  position: absolute;
  top: auto;
  left: -999px;
  overflow: hidden;
  z-index: -999;
  padding-left: 15px; }
  .skipToMainContent:focus {
    height: 20px;
    left: 0; }

.skipToMainContent :first-child {
  text-decoration: none !important; }
